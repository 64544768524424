import React from 'react';
import { Container } from 'react-bootstrap';
import './Home.css'

function Home() {


    return (
        <Container className='d-flex justify-content-center'>
            <video autoPlay muted loop className="bg-video"
                style={{
                    position: 'fixed',
                    objectFit: 'cover',
                    width: '150%',
                    height: '100%',
                    // transform: 'translate(-25%,0)',
                    zIndex: '0'
                }}>
                <source src="assets/img/BNRcovervid.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </Container >
    );
}
export default Home;
