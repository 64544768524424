import React from 'react';
import '../bootstrap.min.css';

function AboutUs() {
    return (
        <div className="container py-5">
            <div className="row mb-4 mb-lg-5">
                <div className="col-md-8 col-xl-6 text-center mx-auto">
                    <h2 className="fw-bold">about us</h2>
                    <p className="text-muted w-lg-50">Despite rumours, we are not associated with The Illuminati...&nbsp;&nbsp;</p>
                </div>
            </div>
            <div className="mx-auto">
                <br />
                <h2>What's all this about?</h2>

                <p> <span className="fw-bold text-success mb-2">“Musical innovation is full of danger to the State, for when modes of music change, the fundamental laws of the State always change with them.”</span> - Plato</p>
                <br />
                <br />
                <h2>What are we doing then?</h2>
                <p>&nbsp;- Producing, sharing and consuming<strong> independent art/media</strong></p>
                <p>&nbsp;- using alternative <strong>mediums of exchange</strong> rather than centralised currencies (aka gold nuggets, bitcoin and coffee)</p>
                <p>&nbsp;- using <strong>de-centralized infrastructure</strong> that promotes freedom of expression</p>
                <p>&nbsp;- Supporting<strong> local businesses</strong> rather than lobbying global corporations that further the agenda of the elite.&nbsp;</p>
                <br />
                <p className="fw-bold text-success mb-2">The plan is to create a self-sustainable community</p>
                <p> <span className="fw-bold text-success mb-2">“The secret of change is to focus all of your energy, not on fighting the old, but on building the new.”</span> - Socrates</p>

                <br />
                <h2>Working with us:</h2>
                <br />
                <p className="fw-bold text-success mb-2">Arists</p>
                <p>We are fairly selective with who we work with at the moment, we only have a certain amount of resources, and hopefully that will grow over time. We love listening to demos, though, they don't have to be proffessionally mixed yet, just send a link to a soundcloud or googledrive in an email to <span className="fw-bold text-success mb-2">info@blindnightrecords.com</span> (make sure to put the work 'submission' in the subject so it goes to the right inbox..</p>
                <br />
                <p className="fw-bold text-success mb-2">Freelancers</p>
                <p>We don't believe in paying people for their time, I don't think you can put a value on that. We work with freelancers and negotiate the fee with them for that piece of work</p>
                <br />
                <p className="fw-bold text-success mb-2">Companies</p>
                <p>We are not associated with MusicBrainz, they do not have permission to use our data about our releases, the data they seem to hold is incorrect, and they won't accept our edit requests. We also don't work with companies that 'deadname' artists even if it's for 'accurate historical record'. </p>

                <p>&nbsp;</p>
                <p>&nbsp;</p>
            </div>
        </div>
    );
}
export default AboutUs;