import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, FloatingLabel } from "react-bootstrap";
import { RiCheckLine, RiErrorWarningLine } from 'react-icons/ri';

const MusicSubmission: React.FC = () => {
  const [name, setName] = useState("");
  const [nameValid, setNameValid] = useState(false);
  const [artistName, setArtistName] = useState("");
  const [artistNameValid, setArtistNameValid] = useState(false);
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [message, setMessage] = useState("");
  const [messageValid, setMessageValid] = useState(false);
  const [city, setCity] = useState("");
  const [cityValid, setCityValid] = useState(false);
  const [trackURL, setTrackURL] = useState("");
  const [trackURLValid, setTrackURLValid] = useState(false);
  const [spotifyURL, setSpotifyURL] = useState("");
  const [spotifyURLValid, setSpotifyURLValid] = useState(false);
  const [selectedInput, setSelectedInput] = useState("");
  const [validForm, setValidForm] = useState(false);

  useEffect(() => {
    handleFormValidation();
    console.log("validation ran");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, artistName, email, city, trackURL, spotifyURL, message]);

  const getHelpText = () => {
    switch (selectedInput) {
      case "Name":
        return true ? "Please enter your full name." : "";
      case "Artist Name":
        return true ? "Please enter the artist's name." : "";
      case "Email":
        return !emailValid ? "Please enter a valid email address." : "looks like an email to me!";
      case "City":
        return true ? "Please enter your city/town." : "";
      case "Track URL":
        return !trackURLValid ? "Please enter your track URL. Must be a soundcloud link, spotify track URL or youtube link" : ":)";
      case "Spotify URL":
        return !spotifyURLValid ? "Please enter your Spotify artist URL." : "looks like a spotify url to me!";
      case "Message":
        return true ? "Write your message here." : "";
      default:
        return "";
    }
  };

  const handleFocus = (input: string) => {
    setSelectedInput(input);
  };

  const handleBlur = () => {
    setSelectedInput("");
    handleFormValidation();
  };

  const handleInputChange = (value, setter) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const event = { target: { value } };
    setter(value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Add your logic for handling the form submission here
  };

  const getIcon = (isValid: boolean) => {
    return isValid
      ?
      <div className="position-absolute top-50 end-0" style={{ transform: "translate(-30%, -50%)" }}>
        <RiCheckLine color='#19f5aa' size={"2.5em"} />
      </div>
      :
      <div className="position-absolute top-50 end-0" style={{ transform: "translate(-59%, -56%)" }}>
        <RiErrorWarningLine color="#dc3545" size={"1.45em"} />
      </div >;
  };

  const handleFormValidation = () => {
    setNameValid(name.length > 0);
    setArtistNameValid(artistName.length > 0);
    setEmailValid(validateEmail(email));
    setCityValid(city.length > 0);
    setTrackURLValid(validateTrackURL(trackURL));
    setSpotifyURLValid(validateSpotifyURL(spotifyURL));
    setMessageValid(message.length > 0 && message.length <= 500);

    setValidForm(
      nameValid &&
      artistNameValid &&
      emailValid &&
      cityValid &&
      trackURLValid &&
      spotifyURLValid &&
      messageValid
    );

  };

  const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validateSpotifyURL = (url: string) => {
    const re = /^https:\/\/open\.spotify\.com\/artist\/[a-zA-Z0-9]+/;
    return re.test(url);
  };

  const validateTrackURL = (url: string) => {
    const soundcloudRegex = /^https:\/\/soundcloud\.com\/.*/;
    const spotifyRegex = /^https:\/\/open\.spotify\.com\/track\/[a-zA-Z0-9]+/;
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;

    return soundcloudRegex.test(url) || spotifyRegex.test(url) || youtubeRegex.test(url);
  };

  const handleMaxChars = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length <= 500) {
      setMessage(e.target.value);
    }
  };

  return (
    <section className="py-5">
      <div className="container py-5">
        <Row className="d-flex justify-content-center">
          <Col md={6} xl={7} className="">
            <Form className="p-3 p-xl-4" onSubmit={handleSubmit} noValidate validated={validForm}>
              <Form.Group className="mb-3" controlId="name">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Name"
                  style={{ color: "black" }}
                >
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    onFocus={() => handleFocus("Name")}
                    onBlur={(e) => { setName(e.target.value); handleBlur() }}
                    required
                    isInvalid={!nameValid}
                    isValid={nameValid}
                    ref={(input) => {
                      if (input) setName(input.value);
                    }}
                  />
                  {getIcon(nameValid)}
                </FloatingLabel>
                <Form.Control.Feedback type="invalid">Please provide a valid name.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="spotifyURL">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Spotify Artist URL"
                  style={{ color: "black" }}
                >
                  <Form.Control
                    type="text"
                    placeholder="Spotify Artist URL"
                    value={spotifyURL}
                    onChange={(e) => {
                      setSpotifyURL(e.target.value);
                    }}
                    onFocus={() => handleFocus("Spotify URL")}
                    onBlur={handleBlur}
                    isInvalid={!spotifyURLValid}
                    isValid={spotifyURLValid}
                    ref={(input) => {
                      if (input) setSpotifyURL(input.value);
                    }}
                  />
                  {getIcon(spotifyURLValid)}
                </FloatingLabel>
                <Form.Control.Feedback type="invalid">Please provide a valid Spotify artist URL.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="artistName">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Artist Name"
                  style={{ color: "black" }}
                >
                  <Form.Control
                    type="text"
                    placeholder="Artist Name"
                    value={artistName}
                    onChange={(e) => {
                      setArtistName(e.target.value);
                    }}
                    onFocus={() => handleFocus("Artist Name")}
                    onBlur={handleBlur}
                    required
                    isInvalid={!artistNameValid}
                    isValid={artistNameValid}
                    ref={(input) => {
                      if (input) setArtistName(input.value);
                    }}
                  />
                  {getIcon(artistNameValid)}
                </FloatingLabel>
                <Form.Control.Feedback type="invalid">Please provide a valid artist name.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="email" >
                <FloatingLabel
                  controlId="floatingInput"
                  label="Email"
                  style={{ color: "black" }}
                >
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    defaultValue={email}
                    value={email}
                    onChange={(e) => handleInputChange(e.target.value, setEmail)}
                    onFocus={() => handleFocus("Email")}
                    onBlur={handleBlur}
                    required
                    isInvalid={!emailValid}
                    isValid={emailValid}
                    autoComplete="on"
                  />
                  {getIcon(emailValid)}
                </FloatingLabel>
                <Form.Control.Feedback type="invalid">Please provide a valid email.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="city">
                <FloatingLabel
                  controlId="floatingInput"
                  label="City/Town"
                  style={{ color: "black" }}
                >
                  <Form.Control
                    type="text"
                    placeholder="City/Town"
                    value={city}
                    onChange={(e) => {
                      setCity(e.target.value);
                    }}
                    onFocus={() => handleFocus("City")}
                    onBlur={handleBlur}
                    required
                    isInvalid={!cityValid}
                    isValid={cityValid}
                    ref={(input) => {
                      if (input) setCity(input.value);
                    }}
                  />
                  {getIcon(cityValid)}
                </FloatingLabel>
                <Form.Control.Feedback type="invalid">Please provide a valid city/town.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="trackURL">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Track URL"
                  style={{ color: "black" }}
                >
                  <Form.Control
                    type="text"
                    placeholder="Track URL"
                    value={trackURL}
                    onChange={(e) => {
                      setTrackURL(e.target.value);
                    }}
                    onFocus={() => handleFocus("Track URL")}
                    onBlur={handleBlur}
                    required
                    isInvalid={!trackURLValid}
                    isValid={trackURLValid}
                    ref={(input) => {
                      if (input) setTrackURL(input.value);
                    }}
                  />
                  {getIcon(trackURLValid)}
                </FloatingLabel>
                <Form.Control.Feedback type="invalid">Please provide a valid track URL.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="message">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Message"
                  style={{ color: "black" }}
                >
                  <Form.Control
                    as="textarea"
                    rows={6}
                    placeholder="Message"
                    value={message}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      e.target.style.height = "30px";
                      e.target.style.height = `${e.target.scrollHeight + 5}px`
                      handleMaxChars(e);
                    }}
                    onFocus={() => handleFocus("Message")}
                    onBlur={handleBlur}
                    isInvalid={!messageValid}
                    isValid={messageValid}
                    maxLength={500}
                    required
                  />
                  {getIcon(messageValid)}
                </FloatingLabel>
                <Form.Control.Feedback type="invalid">Message should not exceed 500 characters.</Form.Control.Feedback>
                <div style={{}}>
                  {message.length}/500
                </div>
              </Form.Group>
              <Button variant="primary" type="submit" className="shadow d-block w-100" disabled={!validForm}>
                SUBMIT
              </Button>
            </Form>
          </Col>
          <Col md={4} xl={4} className="d-flex justify-content-center justify-content-xl-start">
            <div>{getHelpText()}</div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default MusicSubmission;
