export interface Product {
  id: number;
  name: string;
  price: number;
  category: string;
  artist: string;
  sizes: string[];
  canPersonalMessage: boolean;
  colorOptions: string[];
  outOfStock: boolean;
  isNew: boolean;
  imageUrls: string[];
  description: string;
  isActive: boolean;
  onSale: boolean;
  isDigital: boolean;
}

export interface CartItem {
  productId: number;
  name: string;
  quantity: number;
  price: number;
  size: string | null;
  colour: string | null;
  personalMessage: string | null;
  product: Product;
}

export interface NewsItem {
  id: number;
  link: string;
  imgSrc: string;
  date: string;
  title: string;
  description: string;
}
export interface BlogPost {
  id: number;
  title: string;
  description: string;
  thumbnailImgSrc: string | null;
  thumbnailAltText: string;
  thumbnailVidSrc: string | null;
  contentPath: string;
  publishDate: Date;
  author: string;
  visible: boolean;
  category: BlogCategory;
}

export enum BlogCategory {
  RELEASES = "Releases",
  GIGS = "Gigs",
  REVIEWS = "Reviews",
  INTERVIEWS = "Interviews",
  ARTISTSPOTLIGHT = "Artist Spotlight",
  NEWS = "News",
}

export interface CatalogueItem {
  id: number;
  name: string;
  imgSrc: string;
  artist: string;
  date: string;
  contribution: string;
  description: string;
}

export interface Artist {
  id: number;
  name: string;
  associates: Associate[];
}

export interface Associate {
  id: number;
  name: string;
  thumbnailImgSrc: string;
  URL: string;
  artists: Artist[];
  description: string;
}

export interface HomePageCarouselItem {
  id: number;
  title: string;
  caption: string;
  imageSrcURL: string;
  altText: string;
  linkURL: string;
  active: boolean;
}
