// src/components/Blog.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { blogs, BlogCategory } from '../data/testData';

function Blog() {
    const [selectedCategory, setSelectedCategory] = useState("");

    const categories = Object.values(BlogCategory);

    const filteredBlogs = blogs.filter(
        blog => blog.visible && (selectedCategory === "" || blog.category === selectedCategory)
    );

    return (
        <section className="py-5">
            <div className="container">
                <h2 className="fw-bold text-center mb-4">Blog</h2>

                {/* Category Filter */}
                <div className="text-center mb-4">
                    <button
                        className={`btn btn-outline-primary mx-2 ${selectedCategory === "" ? "active" : ""}`}
                        onClick={() => setSelectedCategory("")}
                    >
                        All
                    </button>
                    {categories.map(category => (
                        <button
                            key={category}
                            className={`btn btn-outline-primary mx-2 ${selectedCategory === category ? "active" : ""}`}
                            onClick={() => setSelectedCategory(category)}
                        >
                            {category}
                        </button>
                    ))}
                </div>

                {/* Blog List */}
                <div className="row">
                    {filteredBlogs.map(blog => (
                        <div className="col-md-6 col-lg-4 mb-4" key={blog.id}>
                            <Link to={`/blog/${blog.id}`}>
                                <div className="card shadow">
                                    {blog.thumbnailImgSrc ? (
                                        <img
                                            src={blog.thumbnailImgSrc}
                                            alt={blog.thumbnailAltText}
                                            className="card-img-top"
                                            style={{ height: '200px', objectFit: 'cover' }}
                                        />
                                    ) : blog.thumbnailVidSrc ? (
                                        <video
                                            className="card-img-top"
                                            style={{ height: '200px', objectFit: 'cover' }}
                                            autoPlay
                                            muted
                                            loop
                                        >
                                            <source src={blog.thumbnailVidSrc} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    ) : null}
                                    <div className="card-body">
                                        <span className="badge bg-primary">{blog.publishDate.toLocaleDateString()}</span>
                                        <h5 className="card-title mt-2">{blog.title}</h5>
                                        <p className="text-muted">{blog.description}</p>
                                        <p className="text-muted small">By {blog.author}</p>
                                        <Link to={`/blog/${blog.id}`} className="btn btn-primary btn-sm">
                                            Read More
                                        </Link>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Blog;