import React from 'react';
import { Link } from 'react-router-dom';
import { BsInstagram, BsSpotify } from "react-icons/bs";
import { FaBluesky } from "react-icons/fa6";
import '../bootstrap.min.css';

const Footer = () => {

  return (
    <footer className="bg-dark" style={{ zIndex: '1020' }} >
      <div className="container py-4 py-lg-5">
        <div className="row d-lg-flex justify-content-center justify-content-lg-start">
          <div className="col-sm-4 col-md-3 text-lg-start d-flex flex-column align-items-lg-start item">
            <h3 className="fs-6 fw-bold">info</h3>
            <ul className="list-unstyled">
              <li><Link to="/about-us"> about us</Link></li>
              {/* <li><Link to="/current-vacancies">jobs</Link></li> */}
              {/* <li><Link to="/catalogue">catalogue</Link></li> */}
              <li><Link to="/musicsubmission">music submission</Link></li>
            </ul>
          </div>
        </div>
        <hr />
        <div className="text-muted d-flex justify-content-between align-items-center pt-3">
          <p className="mb-0">Copyright © 2025 Blind Night Records Ltd</p>
          <ul className="list-inline mb-0">
            <li className="list-inline-item">
              <Link target="_blank" to="https://twitter.com/recordsblind">
                <FaBluesky />
              </Link>
            </li>
            <li className="list-inline-item">
              <Link target="_blank" to="https://www.instagram.com/blindnightrecords/">
                <BsInstagram />
              </Link>
            </li>
            <li className="list-inline-item">
              <Link target="_blank" to="https://open.spotify.com/user/yyywpactfzxd9r8aptjfhzy0r?si=c828f775fc714b15">
                <BsSpotify />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;