import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { blogs } from '../data/testData';

function BlogPost() {
    const { id } = useParams();
    const [content, setContent] = useState("");

    useEffect(() => {
        const blog = blogs.find((b) => b.id.toString() === id);

        if (blog) {
            fetch(blog.contentPath)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Failed to load blog content");
                    }
                    return response.text();
                })
                .then((markdown) => setContent(markdown))
                .catch((error) => console.error(error));
        }
    }, [id]);

    return (
        <div className="container py-5">
            {content ? (
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>{content}</ReactMarkdown>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
}

export default BlogPost;